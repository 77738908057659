import React from 'react';
import PropTypes from 'utils/propTypes';
import Cookies from 'universal-cookie';

import classNames from 'classnames';


const cookies = new Cookies();
var profile = "";
// eslint-disable-next-line 
if(cookies.get('token')!=undefined){
   profile = cookies.get('user_data').profile_image;
}
else{
 profile = "fGJP9fQSNZ.png";
}
const Avatar = ({
  rounded,
  circle,
  src,
  size,
  tag: Tag,
  className,
  style,
  ...restProps
}) => {
  const classes = classNames({ 'rounded-circle': circle, rounded }, className);
  return (
    <Tag
      src={src}
      style={{ width: size, height: size, ...style }}
      className={classes}
      {...restProps}
    />
  );
};

Avatar.propTypes = {
  tag: PropTypes.component,
  rounded: PropTypes.bool,
  circle: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  style: PropTypes.object,
};

Avatar.defaultProps = {
  tag: 'img',
  alt:'Avatar',
  rounded: false,
  circle: true,
  size: 40,
  src: '//itabwallet.com/blog/storage/app/public/profile/'+profile,
  style: {},
};

export default Avatar;
