import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
 import Notifications from 'components/Notifications';
 //import SearchInput from 'components/SearchInput';
 import { notificationsData,ButtonWithCircularProgress } from 'demos/header';
import withBadge from 'hocs/withBadge';
import React from 'react';
import Cookies from 'universal-cookie';
import { withRouter} from 'react-router-dom';

import {  MdClearAll,  MdExitToApp,  MdHelp,  MdInsertChart,  MdMessage,  MdNotificationsActive,   MdNotificationsNone,  MdPersonPin,  MdSettingsApplications,} from 'react-icons/md';
import { Configuration } from "@react-md/layout";
import {
  Button,  ListGroup,  ListGroupItem,    Nav,  Navbar,  NavItem,  NavLink,  Popover,  PopoverBody,} from 'reactstrap';
import bn from 'utils/bemnames';
import { store } from 'react-notifications-component';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isBalancePopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };


  toggleUserCardPopover = () => {

    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { isNotificationConfirmed } = this.state;
    const cookies = new Cookies();
if(cookies.get('token')===undefined){
  this.props.history.push('/')
  return false;
}
    var logout = event => {
      
      const pageUrl = 'https://itabwallet.com/blog/public/api/logout';

      const requestOptions = {
        method: 'GET',
        headers: {  'Content-Type': 'application/json' ,'Accept':'application/json'  ,'Authorization':'Bearer ' + cookies.get('token')  },
      };
      fetch(pageUrl, requestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        // eslint-disable-next-line
        if(responseJson.code == 200){

          store.addNotification({
            title: "Message",
            message:""+ responseJson.message +"",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
    
          }      
        })
      cookies.remove('token');
      cookies.remove('type');
     cookies.remove('user_data');
     this.props.history.push('/login')
      return false;
    };
    return (
      <Navbar light expand style={{backgroundColor: '#ffffff'}} className={bem.b('bg-bue')}>
        <Nav navbar className="mr-2">
          <Button outline  onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar>
          {/* <SearchInput /> */}
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem className="d-inline-flex">
            { <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink> }
            { <NavLink id="Popover1" className="position-relative">
           
            </NavLink> }
            { <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover> }
           
            <Configuration>
                <ButtonWithCircularProgress />
            </Configuration>
          </NavItem>

          <NavItem>
            
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={cookies.get('user_data').name}
                  subtitle={cookies.get('user_data').email}
                  text={cookies.get('user_data').amount}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem>
                    <ListGroupItem tag="button" onClick={logout}  action className="border-light">
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default withRouter(Header);
