import PropTypes from 'prop-types';
import React from 'react';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";

import {
 // MdImportantDevices,
  // MdCardGiftcard,
  MdLoyalty,
} from 'react-icons/md';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';


class AuthForm extends React.Component {
  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }
  get isForgotpassword() {
    return this.props.authState === STATE_FPASSWORD;
  }
  get isValidate() {
    return this.props.authState === STATE_VALIDATE;
  }
  get isChangePWd() {
    return this.props.authState === STATE_CHANGEPWD;
  }
  
  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };
 

  //  panform_base = evt => {
  //   var f = evt.target.files[0]; 
  //   const fd = new FormData();
  //   var type = evt.target.getAttribute('data-type');
  //   fd.append('image', evt.target.files[0]);
  //   const requestOptions = {
  //     headers: { 'Content-Type': 'multipart/form-data'},
  //   };
  //   axios.post('https://itabwallet.com/itabwallet/public/api/uploadprofilephoto',fd, requestOptions
  //   ).then(res=>
  //   {
  //     document.getElementById('photo_64').value=res.data
  //    }
  //   );
  //   };

  handleSubmit = event => {
    event.preventDefault();
    const cookies = new Cookies();
    if(this.isLogin){
    var email =  document.getElementById('email').value;
    }
    if(this.isChangePWd ||this.isLogin || this.isSignup)
    var password =  document.getElementById('password').value;
    if(this.isSignup){
    var username =  document.getElementById('username').value;
    }
    if(this.isSignup || this.isChangePWd){
    var c_pwd =  document.getElementById('c_pwd').value;
    }
    if(this.isSignup){
    var mobile =  document.getElementById('mob_num').value;
    }
    var profile = '';
    var type = 2;
    if(this.isSignup){
    var address =  document.getElementById('address').value;
    }
    if(this.isValidate){
    var otp =  document.getElementById('validate_otp').value;
  }
  if(this.isValidate || this.isChangePWd){
    var email1 =  cookies.get('email');
  }
  if(this.isChangePWd){
    var otp1 = cookies.get('otp')
  }
    if(this.isSignup){
     
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({type:type, email: email ,password:password,name:username,c_password:c_pwd,mobile_number:mobile,profile:profile,address:address})
};
  fetch('https://itabwallet.com/blog/public/api/register', requestOptions)
  .then((response) => response.json())
  .then((responseJson) => {
      // eslint-disable-next-line
    if(responseJson.code == 200){
    this.notificationSystem.addNotification({
      title: <MdLoyalty />,
      message:"User Registered Successfully",
      level: 'info',
    });
    }
    else{
        this.notificationSystem.addNotification({
          title: <MdLoyalty />,
          message:responseJson.message,
          level: 'info',
        });
    }   
  
  })
  .catch((error) => {
    console.error(error);
  });
    }
    else if (this.isLogin){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: email ,password:password})
};
  fetch('https://itabwallet.com/blog/public/api/login', requestOptions)
  .then((response) => response.json())
  .then((responseJson) => {
      // eslint-disable-next-line
    if(responseJson.code == 200){
    const type = responseJson.data.user_type;
    const token = responseJson.token;
      cookies.set('token', token, { path: '/' });
      cookies.set('type', type, { path: '/' });  
      cookies.set('user_data', responseJson.data, { path: '/' });
      this.setState({data: type, token :  token});
      this.props.onChangeAuthlogin(type,token);
      this.notificationSystem.addNotification({
        title: <MdLoyalty />,
        message: responseJson.message,
        level: 'info',
      });
    }
    else{
        this.notificationSystem.addNotification({
          title: <MdLoyalty />,
          message: responseJson.message,
          level: 'warning',
        });
    }   
  
  })
  .catch((error) => {
    console.error(error);
  });

} else if(this.isValidate){
 const requestOptionsotp = {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({ remember_token:otp,email:email1 })
};
 fetch('https://itabwallet.com/blog/public/api/varify-otp', requestOptionsotp)
 .then((response) => response.json())
 .then((responseJson) => {
   // eslint-disable-next-line 
   if(responseJson.code == 200){
    this.notificationSystem.addNotification({
      title: <MdLoyalty />,
      message: responseJson.message,
      level: 'info',
    });
    const type = "changepwd"
    cookies.set('otp', otp, { path: '/' });
    this.props.onChangeAuthlogin(type);
   }
   else{
       this.notificationSystem.addNotification({
         title: <MdLoyalty />,
         message: responseJson.message,
         level: 'warning',
       });
   }   
 
 })
 .catch((error) => {
   console.error(error);
 });
}
else if(this.isChangePWd){
 
 const requestOptionsotp = {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({ otp:otp1,user_id:email1,new_password:password,confirm_password:c_pwd })
};
 fetch('https://itabwallet.com/blog/public/api/password/reset', requestOptionsotp)
 .then((response) => response.json())
 .then((responseJson) => {
   // eslint-disable-next-line 
   if(responseJson.code == 200){
    this.notificationSystem.addNotification({
      title: <MdLoyalty />,
      message: responseJson.message,
      level: 'info',
    });
    const type = "signup"
    cookies.set('otp', otp, { path: '/' });
    this.props.onChangeAuthlogin(type);
   }
   else{
       this.notificationSystem.addNotification({
         title: <MdLoyalty />,
         message: responseJson.message,
         level: 'warning',
       });
   }   
 
 })
 .catch((error) => {
   console.error(error);
 });
}
else{
 const requestOptions = {
   method: 'POST',
   headers: { 'Content-Type': 'application/json' },
   body: JSON.stringify({ email: email })
};
 fetch('https://itabwallet.com/blog/public/api/password/email', requestOptions)
 .then((response) => response.json())
 .then((responseJson) => {
   // eslint-disable-next-line 
   if(responseJson.code == 200){
    this.notificationSystem.addNotification({
      title: <MdLoyalty />,
      message: responseJson.message,
      level: 'info',
    });
    const type = "validate"
    cookies.set('email', email, { path: '/' });
    this.props.onChangeAuthlogin(type);
   }
   else{
       this.notificationSystem.addNotification({
         title: <MdLoyalty />,
         message: responseJson.message,
         level: 'info',
       });
   }   
 
 })
 .catch((error) => {
   console.error(error);
 });
}
   
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }
    if (!buttonText && this.isForgotpassword) {
      return 'Verify';
    }
    if (!buttonText && this.isValidate) {
      return 'Validate Otp';
    }
    if (!buttonText && this.isChangePWd) {
      return 'Change Password';
    }

    return buttonText;
  }

  render() {
    return (
  
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in with
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/github.svg")}
                    />
                    Github
                  </button>
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg")}
                    />
                    Google
                  </button>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div>
                <form>
                {this.isSignup && (
                    <div className="relative w-full mb-3">
                              
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Username
                    </label>
                    <input
                      type="text"  id="username"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Username"
                    />
                  </div>
                  )}
                    {(this.isSignup  || this.isLogin  || this.isForgotpassword) && (
                  
                  <div className="relative w-full mb-3">
                    
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"  id="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div>
                   )}
                  {this.isSignup && (
                    <div className="relative w-full mb-3">
                              
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Mobile
                    </label>
                    <input
                      type="text"  id="mob_num"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Mobile"
                    />
                  </div>
                  )}
      {(this.isSignup || this.isChangePWd || this.isLogin) && (
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input 
                      type="password"  id="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>
                    )}

                  {(this.isSignup || this.isChangePWd) && (
                    <div className="relative w-full mb-3">
                              
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                     Confirm Password
                    </label>
                    <input
                      type="password"  id="c_pwd"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Confirm password"
                    />
                  </div>
                  )}
                      {this.isSignup && (
                    <div className="relative w-full mb-3">
                              
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Address
                    </label>
                    <input
                      type="text"  id="address"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Address"
                    />
                  </div>
                  )}
                      {this.isValidate && (
                    <div className="relative w-full mb-3">
                              
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Validate Otp
                    </label>
                    <input
                      type="Password"  id="validate_otp"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="validate_otp"
                    />
                  </div>
                  )}
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button 
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"     onClick={this.handleSubmit}>
                       {this.renderButtonText()}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
              <Link to="/forgotpassword" className="text-blueGray-200">
                  <small>Forgot password?</small>
                </Link>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/signup" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <NotificationSystem
          dismissible={true}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </div>
    
      
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';
export const STATE_FPASSWORD = 'FORGOT';
export const STATE_VALIDATE = 'VALIDATE';
export const STATE_CHANGEPWD = 'CHANGEPWD';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP,STATE_FPASSWORD,STATE_VALIDATE,STATE_CHANGEPWD]).isRequired,
};


export default AuthForm;
