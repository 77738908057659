import React ,{useEffect}from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

const Footer = () => {



useEffect(() => {
   // eslint-disable-next-line
  var Tawk_API=Tawk_API||{};
  // eslint-disable-next-line
  var Tawk_LoadStart=new Date();
  (function(){
  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/5d47086f7d27204601c93a0e/default';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
  })();

  
}, [])
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
          2020 ItabWallet 
        </NavItem>
      </Nav>
   
    </Navbar>
    
  );
};

export default Footer;
