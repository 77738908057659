import logo200Image from 'assets/img/logo/logo_200.png';
import SourceLink from 'components/SourceLink';
import React from 'react';
import {
 // MdAccountCircle,
  MdBorderAll,
  MdDashboard,
    MdExtension,
  MdKeyboardArrowDown,
  //MdSend,
 // MdTextFields,
  //MdViewCarousel,
 // MdViewDay,
 // MdViewList,
 // MdWeb,
 // MdWidgets,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import Cookies from 'universal-cookie';

import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';


const cookies = new Cookies();

  const navItemsadmin = [
    { to: '/users', name: 'Users', exact: true, Icon: MdDashboard },
    // { to: '/wallet', name: 'Wallet', exact: false, Icon: MdBorderAll },
    // { to: '/pan', name: 'Pan', exact: false, Icon: MdBorderAll },  
  ];

const navItems = [
  { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/wallet', name: 'Wallet', exact: false, Icon: MdBorderAll },
  { to: '/pan', name: 'Pan', exact: false, Icon: MdBorderAll },
  { to: '/recharge', name: 'Recharge', exact: false, Icon: MdBorderAll },
  { to: '/ration', name: 'Ration', exact: false, Icon: MdBorderAll },
  


];
const navComponents = [
  { to: '/community', name: 'Community', exact: false, Icon: MdBorderAll },
  { to: '/obc', name: 'OBC Certificate', exact: false, Icon: MdBorderAll },
  { to: '/nativity', name: 'Native', exact: false, Icon: MdBorderAll },
  { to: '/income', name: 'Income', exact: false, Icon: MdBorderAll },
  { to: '/fg', name: 'First Gradute', exact: false, Icon: MdBorderAll },
  { to: '/ict', name: 'Inter Caste', exact: false, Icon: MdBorderAll },
  { to: '/legal', name: 'Legal Heir Caste', exact: false, Icon: MdBorderAll },
  { to: '/nmc', name: 'No male Child', exact: false, Icon: MdBorderAll },
  { to: '/widow', name: 'Widow', exact: false, Icon: MdBorderAll },
  { to: '/unmarried', name: 'Unmarried', exact: false, Icon: MdBorderAll },
  { to: '/unemloyment', name: 'UnEmployment', exact: false, Icon: MdBorderAll },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: false,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b('bg-black ')} >
        <div className={bem.e('background')} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              />
              <span className="text-white">
                ItabWallet
              </span>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
             {
            (cookies.get('type') === '1')?(

             navItemsadmin.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>

            ))):('')
            
            }
             <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Components')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start text-uppercase">Certificate</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenComponents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenComponents}>
              {navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> 
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
