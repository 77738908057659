import AuthForm, { STATE_LOGIN,STATE_SIGNUP } from 'components/AuthForm';
import React from 'react';
import Cookies from 'universal-cookie';


class AuthPage extends React.Component {
  
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/');
    } else if(authState === STATE_SIGNUP) {
      this.props.history.push('/signup');
    }
    else{
      this.props.history.push('/forgotpassword');
    }
  };

  handleLogoClick = () => {
    const cookies = new Cookies();
    if(cookies['token'] === null){
    this.props.history.push('/');
    }
    else{
      this.props.history.push('/wallet');
    }
  };
  handleLoginClick = (type) => {
    const cookies = new Cookies();
  
    if(cookies['token'] === null){
    this.props.history.push('/');
    }
    else if (type === 1) {
      this.props.history.push('/wallet');
    }
    else if(type === 'validate') {
   
      this.props.history.push('/validate');
     } 
    else if(type === 'changepwd') {
      this.props.history.push('/changepassword');
     } 
     else if(type === 'signup') {
      this.props.history.push('/');
     } 
    else {
     this.props.history.push('/wallet');
    }
  
  };

  render() {
    return (
      <main>
      <section className="relative w-full h-full py-40 min-h-screen">
        <div
          className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          style={{
            backgroundImage:"url(" + require("assets/img/register_bg_2.png")}}
        ></div>        
        
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
              onChangeAuthlogin={this.handleLoginClick}
            />
            
      </section>
      </main>
    
    );
  }
}

export default AuthPage;
