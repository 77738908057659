import { STATE_LOGIN, STATE_SIGNUP,STATE_FPASSWORD,STATE_VALIDATE,STATE_CHANGEPWD } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import Index from "views/Index.js";



//const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
//const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const WalletPage = React.lazy(() => import('pages/WalletPage'));
const PanCard = React.lazy(() => import('pages/PanCard'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const AdminUser = React.lazy(() => import('pages/AdminUser'));
const Recharge = React.lazy(() => import('pages/RechargePage'));
const Community = React.lazy(() => import('pages/Community'));
const NativityPage = React.lazy(() => import('pages/NativityPage'));
const IncomePage = React.lazy(() => import('pages/IncomePage'));
const FirstGradute = React.lazy(() => import('pages/FirstgradPage'));
const InterCastePage = React.lazy(() => import('pages/InterCastePage'));
const OBCPage = React.lazy(() => import('pages/OBCPage'));
const LegalHeirPage = React.lazy(() => import('pages/LegalHeirPage'));
const NMCPage = React.lazy(() => import('pages/NMCPage'));
const WidowPage = React.lazy(() => import('pages/WidowPage'));
const UnmarriedPage = React.lazy(() => import('pages/UnmarriedPage'));
const UnEmPage = React.lazy(() => import('pages/UnEmPage'));
const RationPage = React.lazy(() => import('pages/RationPage'));
//const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
//const FormPage = React.lazy(() => import('pages/FormPage'));
//const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
//const ModalPage = React.lazy(() => import('pages/ModalPage'));
//const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
 // const TablePage = React.lazy(() => import('pages/TablePage'));
//const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
//const WidgetPage = React.lazy(() => import('pages/WidgetPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};



  
class App extends React.Component {
 
  render() {
    return (
      
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={Index}
            />
             <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              ) }
            />
            <LayoutRoute
              exact
              path="/signup"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />

<LayoutRoute
              exact
              path="/forgotpassword"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_FPASSWORD} />
              )}
            />
            <LayoutRoute
              exact
              path="/validate"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_VALIDATE} />
              )}
            />
            <LayoutRoute
              exact
              path="/changepassword"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_CHANGEPWD} />
              )}
            />

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/dashboard" render={()=> <DashboardPage />}  />
              <Route exact path="/dashboard" render={()=> <DashboardPage />}  />
              <Route exact path="/recharge" render={()=> <Recharge />}  />
              <Route exact path="/community" render={()=> <Community />}  />
              <Route exact path="/nativity" render={()=> <NativityPage />}  />
              <Route exact path="/income" render={()=> <IncomePage />}  />
              <Route exact path="/fg" render={()=> <FirstGradute />}  />
              <Route exact path="/ict" render={()=> <InterCastePage />}  />
              <Route exact path="/unmarried" render={()=> <UnmarriedPage />}  />
              <Route exact path="/obc" render={()=> <OBCPage />}  />
              <Route exact path="/legal" render={()=> <LegalHeirPage />}  />
              <Route exact path="/nmc" render={()=> <NMCPage />}  />
              <Route exact path="/cards" render={()=> <CardPage />}  />
              <Route exact path="/wallet" render={()=> <WalletPage />}  />
              <Route exact path="/pan" render={()=> <PanCard />}  />
              <Route exact path="/users" render={()=> <AdminUser />}  />
              <Route exact path="/widow" render={()=> <WidowPage />}  />
              <Route exact path="/unemloyment" render={()=> <UnEmPage />}  />
              <Route exact path="/ration" render={()=> <RationPage />}  />
              <Route exact path="/auth" render={()=> <RationPage />}  />
        
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
