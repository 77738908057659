import user1Image from 'assets/img/users/100_1.jpg';
import user2Image from 'assets/img/users/100_2.jpg';
import user3Image from 'assets/img/users/100_3.jpg';
import user4Image from 'assets/img/users/100_4.jpg';
import user5Image from 'assets/img/users/100_5.jpg';

import React, { useEffect,useState } from "react";
import { TextIconSpacing } from "@react-md/icon";
import { Button } from "@react-md/button";
import { useTimeout, useToggle } from "@react-md/utils";
import Cookies from 'universal-cookie';
import { IoIosCash } from "react-icons/io";
import Spinner from 'react-bootstrap/Spinner'
export const notificationsData = [
  {
    id: 1,
    avatar: user1Image,
    message: 'David sent you a message',
    date: '3 min ago',
  },
  {
    id: 2,
    avatar: user2Image,
    message: 'Jane mentioned you here',
    date: '1 hour ago',
  },
  {
    id: 3,
    avatar: user3Image,
    message: 'Clark sent a pull request',
    date: 'yesterday',
  },
  {
    id: 4,
    avatar: user4Image,
    message: 'Alicia signed up',
    date: '3 days ago',
  },
  {
    id: 5,
    avatar: user5Image,
    message: 'Keith shared this article',
    date: 'a week ago',
  },
  {
    id: 6,
    avatar: user5Image,
    message: 'Keith shared this article',
    date: 'a week ago',
  },
];


export const ButtonWithCircularProgress= (e) => {
  const pageUrl = 'https://itabwallet.com/blog/public/api/getBalance';

  const cookies = new Cookies();
  const [loading, enable, disable] = useToggle(false);
  const [balance, setbalance] = useState('');
  const [start] = useTimeout(disable, 0);
  useEffect(() => {
    if (loading) {
     
      const requestOptions = {
        method: 'POST',
        headers: {  'Content-Type': 'application/json' ,'Accept':'application/json'  ,'Authorization':'Bearer ' + cookies.get('token')  },
        body: JSON.stringify({})
      };
      fetch(pageUrl, requestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        setbalance(responseJson);
          start(); 
        
      })
    }
  }, [loading, start]);

  const id = "button-with-progress";
  return (
  
    <Button
      id={id}
      onClick={enable}
      outline color="success"
      theme={loading ? "secondary" : "success"}
      themeType=""
    >
      <TextIconSpacing
        icon={
          loading ? (
            <Spinner animation="grow" variant="success" />
            ) : (
            <IoIosCash />
          )
        }
      >
         { balance ? balance  : 'Balance' }
        
      </TextIconSpacing>
    </Button>
  );
}